<template>
  <div>
    <div class="editor"
      v-html="htmlData(content.content)"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "typeHtmlPreview",
  props:['content'],
  computed: {
    ...mapState(["landing"]),
    dateToday: function() {
      return moment().format("DD.MM.YYYY");
    },
  },
  data() {
    return {
      datenow: "",
    };
  },
  methods: {
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data, type) {
      if(data){
      const weatherInd = data.split(" ").indexOf(":weather:");
      let result = data.split(" ");
      if (weatherInd && this.landing.advanced.global.show_weather) {
        const newArr = result;
        newArr[weatherInd] =
          this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
            0
          ) + " ℃";
        result = newArr;
      }
      const timeInd = data.split(" ").indexOf(":time:");
      if (timeInd) {
        const newArr = result;
        newArr[timeInd] = this.datenow;
        result = newArr;
      }
      const dateInd = data.split(" ").indexOf(":date:");
      if (dateInd) {
        const newArr = result;
        newArr[dateInd] = this.dateToday;
        result = newArr;
      }
      const user_name = data.split(" ").indexOf(":name:");
      if (
        user_name &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_name] = "Agent";
        result = newArr;
      }
      const user_surname = data.split(" ").indexOf(":surname:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_surname] = "Smith";
        result = newArr;
      }
      const user_birthday = data.split(" ").indexOf(":birthday:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_birthday] = "24.03.1999";
        result = newArr;
      }
      const user_room = data.split(" ").indexOf(":room:");
      if (
        user_surname &&
        this.landing.advanced.global.auth &&
        this.landing.advanced.auth.name &&
        type === "title"
      ) {
        const newArr = result;
        newArr[user_room] = "707";
        result = newArr;
      }

      const user_auth = data.split(" ").indexOf(":auth:");
      if (user_auth && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_auth] = `<a href="#">Login</a>`;
        result = newArr;
      }
      const user_logout = data.split(" ").indexOf(":logout:");
      if (user_logout && this.landing.advanced.global.auth && !type) {
        const newArr = result;
        newArr[user_logout] = `<a href="#">Logout</a>`;
        result = newArr;
      }
      return result.join(" ");

      }
    },
  },
  mounted(){
    this.timeNow();
  }
};
</script>

<style lang="scss"></style>
